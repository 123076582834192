// import fetch from '../libs/fetch';
import { COMMON_SERVER_HEADERS, RETAIL_BACKEND } from '@/constants';
import {
    IBookedVehicleDetailsResult,
    IFinanceApplicationGetResult,
} from '@/types';
import To from '@/libs/to-promise';
import pino from 'pino';
const logger = pino().child({
    s: 'src/api-requests/finance-application.apis.ts',
});

export async function getFinanceApplicationData(
    id: string
): Promise<IFinanceApplicationGetResult> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/finance/${id}/thankyou`, {
            headers: { ...COMMON_SERVER_HEADERS },
        })
    );
    if (err) {
        logger.error(err);
        return {} as IFinanceApplicationGetResult;
    }
    return res.json();
}

export async function getBookedVehicleByBookingId(
    id: string
): Promise<IBookedVehicleDetailsResult> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/bookings/${id}/vehicle-details`, {
            headers: { ...COMMON_SERVER_HEADERS },
        })
    );
    if (err) {
        logger.error(err);
        return {} as IBookedVehicleDetailsResult;
    }
    let [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info(
            'Error while trying to parse getBookedVehicleByBookingId   response.'
        );
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IBookedVehicleDetailsResult;
    }
    return data;
}
