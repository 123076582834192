import { SYLNDR_USER_SERVICE_BASE_URL } from '@/constants';
import {
    IAuthUpdateInfoResponse,
    IAuthGetUpdatedToken_RESULT,
    IUpdateUserInfoBody,
    IUpdateUserInfoRequestParameters,
    IUserSignInReponse,
} from '@/types';
import { fetchWithInterceptorInstance } from '@/classes/FetchWithInterceptor';
import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/auth' });

export const updateUserInfo = async ({
    id,
    name,
    email,
    emptyEmail,
}: IUpdateUserInfoRequestParameters): Promise<IAuthUpdateInfoResponse> => {
    const url = `${SYLNDR_USER_SERVICE_BASE_URL}/v1.0/user/${id}`;
    const updateBody: Partial<IUpdateUserInfoBody> = {};
    if (name) updateBody.name = name;
    if (email && email.length) {
        updateBody.email = email;
    }
    if (emptyEmail) {
        updateBody.email = '';
    }

    const [err, res] = await To(
        fetchWithInterceptorInstance.use({
            url,
            method: 'PATCH',
            body: updateBody,
        })
    );
    if (err) {
        logger.error(err);
        return {} as IAuthGetUpdatedToken_RESULT;
    }
    return res;
};
export const getUpdatedToken =
    async (): Promise<IAuthGetUpdatedToken_RESULT> => {
        const url = `${SYLNDR_USER_SERVICE_BASE_URL}/v1.0/user-auth/refresh-token`;
        const [err, res] = await To(
            fetchWithInterceptorInstance.use({ url, method: 'GET' })
        );
        if (err) {
            logger.error(err);
            return {} as IAuthGetUpdatedToken_RESULT;
        }
        return res;
    };
export const userAuthSignIn = async ({
    phone,
    otp,
}: {
    phone: string;
    otp: string;
}): Promise<IUserSignInReponse | Error> => {
    const url = `${SYLNDR_USER_SERVICE_BASE_URL}/v1.0/user-auth/sign-in`;
    const requestBody = {
        phone,
        otp,
    };
    const [err, res] = await To(
        fetchWithInterceptorInstance.use({
            url,
            method: 'POST',
            body: requestBody,
        })
    );
    if (err) {
        logger.error(err);
        return err;
    }
    return res;
};
