import {
    SYLNDR_OTP_SERVICE_BASE_URL,
    COMMON_SERVER_HEADERS,
} from '@/constants';
import { IGenericResponse } from '@/types';

import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/otp' });
const otpHeaders = {
    'Content-Type': 'application/json',
    ...COMMON_SERVER_HEADERS,
};

export const sendOtp = async ({
    phone,
    language,
}: {
    phone: string;
    language: string;
}): Promise<IGenericResponse> => {
    const url = `${SYLNDR_OTP_SERVICE_BASE_URL}/v1.0/otp/sms/send`;
    const [err, res] = await To(
        fetch(url, {
            method: 'POST',
            headers: otpHeaders,
            body: JSON.stringify({
                phone,
                language,
            }),
        })
    );
    if (err) {
        logger.error(err);
        return {} as IGenericResponse;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse sendOtp response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IGenericResponse;
    }
    return data;
};
