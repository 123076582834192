// import fetch from '../libs/fetch';
import {
    CAR_LISTING_VALIDATION_TAG,
    COMMON_SERVER_HEADERS,
    NEXT_APP_SERVER_FETCH_CACHE_ENABLED,
    RETAIL_BACKEND,
} from '@/constants';
import { IBodyTypeResult, IPopularBrnadsResult } from '@/types';
import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/car-details/index.ts' });

//  86400000 = one day
export async function getPopularBrands(): Promise<IPopularBrnadsResult> {
    const requestOptions: RequestInit = {
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
        },
    };
    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED) {
        requestOptions.next = { tags: [CAR_LISTING_VALIDATION_TAG] };
    }
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/vehicles/popular-brands`, requestOptions)
    );
    if (err) {
        logger.info('Error while trying to parse getPopularBrands response.');
        logger.info(`request options are ${JSON.stringify(requestOptions)}`);
        logger.error(err);
        return {} as IPopularBrnadsResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse getPopularBrands response.');
        logger.info(`request options are ${JSON.stringify(requestOptions)}`);
        logger.info(`original data provided is ${JSON.stringify(data)}`);
        logger.error(dataParseError);
        return {} as IPopularBrnadsResult;
    }
    return data;
}

export async function getBodyTypes(): Promise<IBodyTypeResult> {
    const requestOptions: RequestInit = {
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
        },
    };
    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED) {
        requestOptions.next = { tags: [CAR_LISTING_VALIDATION_TAG] };
    }
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/vehicles/body-types`, requestOptions)
    );
    if (err) {
        logger.info('Error while trying to parse getBodyTypes response.');
        logger.info(`request options are ${JSON.stringify(requestOptions)}`);
        logger.error(err);
        return {} as IBodyTypeResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse getBodyTypes response.');
        logger.info(`request options are ${JSON.stringify(requestOptions)}`);
        logger.info(`original data provided is ${JSON.stringify(data)}`);
        logger.error(dataParseError);
        return {} as IBodyTypeResult;
    }
    return data;
}
