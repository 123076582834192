// import fetch from '../libs/fetch';
import { COMMON_SERVER_HEADERS, RETAIL_BACKEND } from '@/constants';
import { defaultSearchTermsResponse, GetBanners, ISearchTerms } from '@/types';
import { TIME_IN_SECONDS } from '@/constants/time';
import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/car-listing.apis.ts' });

export async function getBanners(): Promise<GetBanners> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/banners/public`, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate: TIME_IN_SECONDS.minute * 30,
            },
        })
    );
    if (err) {
        logger.info(err);
        return {} as GetBanners;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse car imperfection response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as GetBanners;
    }
    return data;
}

export async function getSearchTerms(): Promise<ISearchTerms> {
    const defaultResponse = {
        ...defaultSearchTermsResponse,
    };
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/vehicles/retail/public/search-terms`, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate: TIME_IN_SECONDS.minute * 10,
                //IS_IGNORING_CACHE_SETTING.
            },
        })
    );
    if (err) {
        logger.info(err);
        return defaultResponse;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse search-terms response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return defaultResponse;
    }
    return data.statusCode === 200
        ? { ...defaultResponse, ...data.data }
        : defaultResponse;
}
