import {
    BookingType,
    GetCarDetailsResponse,
    GetCarListing,
    ImperfectionReportGrouppedResult,
    ImperfectionReportResult,
    ReservationType,
} from '@/types';
import {
    AVAILABLE_LANGUAGES,
    CAR_LISTING_VALIDATION_TAG,
    COMMON_SERVER_HEADERS,
    DEFAULT_LANGUAGE,
    IMPERFECTION_VALIDATION_TAG,
    NEXT_APP_SERVER_FETCH_CACHE_ENABLED,
    RETAIL_BACKEND,
} from '@/constants';
import To from '@/libs/to-promise';
import { notFound } from 'next/navigation';
import { BookingStatusType } from '@/types/car-details/booking-status.types';
import Logger from '@/utils/logger';
import { getCarListingsElastic } from '@/api-requests/car-listing-elastic.apis';

const localLogger = Logger.child({
    s: 'src/api-requests/car-details/index.ts',
});

interface getCarDetailsResponseJson {
    message?: string;
    data?: {
        id?: string;
    };
}
const getCarDetails = async ({
    carId = 'd81b05c8-2510-48fb-9950-0b6111e0bbc7',
    accessKey,
    lang = DEFAULT_LANGUAGE,
}: {
    carId: string;
    accessKey?: string;
    lang?: AVAILABLE_LANGUAGES;
}): Promise<GetCarDetailsResponse> => {
    if (!accessKey) {
        const [, ElasticResponse] = await To(
            getCarListingsElastic({
                fields: [],
                filters: {
                    must_not: [],
                    must: [
                        {
                            term: {
                                id: carId,
                            },
                        },
                    ],
                    should: [],
                },
            })
        );
        if (ElasticResponse && ElasticResponse.total === 1) {
            //@ts-ignore
            return {
                statusCode: 200,
                success: true,
                data: ElasticResponse.data[0],
            } as GetCarDetailsResponse;
        }
    }
    const fnLogger = localLogger.child({ fn: 'getCarDetails' });
    const url = `${RETAIL_BACKEND}/vehicles/retail/public/${carId}${
        accessKey ? `?accessKey=${accessKey}` : ''
    }`;

    // disable revalidation for admins.
    const requestOptions: RequestInit = {
        next: { revalidate: 20 },
        headers: {
            ...COMMON_SERVER_HEADERS,
            lang: lang,
        },
    };
    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED && !accessKey) {
        requestOptions.next = { tags: [CAR_LISTING_VALIDATION_TAG] };
    }
    const [err, res] = await To(fetch(url, requestOptions));

    if (err) {
        fnLogger.info('Error while trying to get car details.');
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);
        fnLogger.error(err);
        throw err;
    }

    if (res.status === 404) {
        notFound();
    }

    const [dataParseError, data] = await To(res.json());
    if (dataParseError || !(data?.data?.id as getCarDetailsResponseJson)) {
        fnLogger.info('Error while trying to parse car response.');
        fnLogger.info(
            `Checking condition is (data?.data?.id as getCarDetailsResponseJson) ${JSON.stringify(
                data?.data?.id as getCarDetailsResponseJson
            )}`
        );
        fnLogger.info(`original data provided is ${JSON.stringify(data)}`);
        fnLogger.error(dataParseError);
        notFound();
    }
    return data;
};
// car-listing
const getImperfectionReport = async ({
    carId,
    lang = DEFAULT_LANGUAGE,
}: {
    carId: string;
    lang?: 'en' | 'ar';
}): Promise<ImperfectionReportResult> => {
    const fnLogger = localLogger.child({ fn: 'getImperfectionReport' });
    const url = `${RETAIL_BACKEND}/vehicles/${carId}/public/imperfections`;
    const requestOptions: RequestInit = {
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
            lang: lang,
        },
    };

    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED) {
        requestOptions.next = {
            tags: [`${IMPERFECTION_VALIDATION_TAG}${carId}`],
        };
    }

    const [err, res] = await To(fetch(url, requestOptions));
    if (err) {
        fnLogger.info(
            'Error while trying to fetch imperfection reports of the car'
        );
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);

        fnLogger.error(err);
        return {} as ImperfectionReportResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        fnLogger.info('Error while trying to parse car imperfection response.');
        fnLogger.info(`original data provided is ${JSON.stringify(data)}`);
        fnLogger.error(dataParseError);
        return {} as ImperfectionReportResult;
    }
    return data;
};
const getImperfectionReportGroupped = async ({
    carId,
    lang = DEFAULT_LANGUAGE,
}: {
    carId: string;
    lang?: 'en' | 'ar';
}): Promise<ImperfectionReportGrouppedResult> => {
    const fnLogger = localLogger.child({ fn: 'getImperfectionReportGroupped' });
    const url = `${RETAIL_BACKEND}/vehicles/${carId}/public/imperfections-grouped`;
    const requestOptions: RequestInit = {
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
            lang: lang,
        },
    };

    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED) {
        requestOptions.next = {
            tags: [`${IMPERFECTION_VALIDATION_TAG}${carId}`],
        };
    }

    const [err, res] = await To(fetch(url, requestOptions));
    if (err) {
        fnLogger.info(
            'Error while trying to fetch imperfection reports of the car'
        );
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);

        fnLogger.error(err);
        return {} as ImperfectionReportGrouppedResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        fnLogger.info('Error while trying to parse car imperfection response.');
        fnLogger.info(`original data provided is ${JSON.stringify(data)}`);
        fnLogger.error(dataParseError);
        return {} as ImperfectionReportGrouppedResult;
    }
    return data;
};
const getSimilarCars = async ({
    carId,
    lang = DEFAULT_LANGUAGE,
}: {
    carId: string;
    lang?: 'en' | 'ar';
}): Promise<GetCarListing> => {
    const fnLogger = localLogger.child({ fn: 'getSimilarCars' });
    const url = `${RETAIL_BACKEND}/vehicles/retail/public/${carId}/similar-cars`;
    const requestOptions: RequestInit = {
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
            lang: lang,
        },
    };
    if (NEXT_APP_SERVER_FETCH_CACHE_ENABLED) {
        requestOptions.next = { tags: [CAR_LISTING_VALIDATION_TAG] };
    }
    const [err, res] = await To(fetch(url, requestOptions));
    if (err) {
        fnLogger.info('Error while trying to fetch similar cars');
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);

        fnLogger.error(err);
        return {} as GetCarListing;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        fnLogger.info('Error while trying to parse similar cars response.');
        fnLogger.info(`original data provided is ${JSON.stringify(data)}`);
        fnLogger.error(dataParseError);
        return {} as GetCarListing;
    }
    return data;
};
const getCarShowroomCalendar = async ({
    carId,
    lang = DEFAULT_LANGUAGE,
    bookingType,
    reservationType,
}: {
    carId: string;
    lang?: 'en' | 'ar';
    bookingType: BookingType;
    reservationType: ReservationType;
}) => {
    const fnLogger = localLogger.child({ fn: 'getCarShowroomCalendar' });
    const url = `${RETAIL_BACKEND}/showrooms/calendar/${carId}/${bookingType}/${reservationType}`;

    const requestOptions = {
        //strictly disable caching for this endpoint.
        next: { revalidate: 0 },
        headers: {
            ...COMMON_SERVER_HEADERS,
            lang: lang,
        },
    };
    const [err, res] = await To(fetch(url, requestOptions));

    if (err) {
        fnLogger.info(
            'Error while trying to fetch getCarShowroomCalendar cars'
        );
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);

        fnLogger.error(err);
        return {};
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        fnLogger.info(
            'Error while trying to parse getCarShowroomCalendar  response.'
        );
        fnLogger.info(`original data provided is ${JSON.stringify(data)}`);
        fnLogger.error(dataParseError);
        return {};
    }
    return data;
};

export async function getBookingStatus(
    paymentTempId: string
): Promise<BookingStatusType> {
    const fnLogger = localLogger.child({ fn: 'getBookingStatus' });
    const url = `${RETAIL_BACKEND}/bookings/${paymentTempId}/status`;
    const requestOptions = {
        //strictly disable caching for this endpoint.
        next: { revalidate: 0 },
        headers: { lang: DEFAULT_LANGUAGE, ...COMMON_SERVER_HEADERS },
    };
    const [err, res] = await To(fetch(url, requestOptions));

    if (err) {
        fnLogger.info('Error while trying to fetch getBookingStatus ');
        fnLogger.info(`calling url is ${url}`);
        fnLogger.info(`request options are ${JSON.stringify(requestOptions)}`);

        fnLogger.error(err);
        throw err;
    }
    const [dataParseError, resJson] = await To(res.json());

    if (dataParseError) {
        fnLogger.info(
            'Error while trying to parse getBookingStatus  response.'
        );
        fnLogger.info(`original data provided is ${JSON.stringify(resJson)}`);
        fnLogger.error(dataParseError);
        throw dataParseError;
    }
    const { data } = resJson;

    return data;
}

export const carDetailsService = {
    getCarDetails,
    getImperfectionReport,
    getSimilarCars,
    getCarShowroomCalendar,
    getBookingStatus,
    getImperfectionReportGroupped,
};
