import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth_REDUCER, AuthStepsEnum } from '@/types';

const initialState: IAuth_REDUCER = {
    progressValue: 33,
    currentStep: AuthStepsEnum.spinner,
    phone: '',
    otp: '',
    name: '',
    email: '',
    userId: '',
    showProfileCard: false,
    isLoggedIn: false,
    redirectUrl: '',
};

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setProgressValue: (state, action: PayloadAction<number>) => {
            state.progressValue = action.payload;
        },
        setLoginCurrentStep: (state, action: PayloadAction<AuthStepsEnum>) => {
            state.currentStep = action.payload;
        },
        setLoginPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setLoginOtp: (state, action: PayloadAction<string>) => {
            state.otp = action.payload;
        },
        setLoginName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setLoginEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setShowProfileCard: (state, action: PayloadAction<boolean>) => {
            state.showProfileCard = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
        clearCurrentState: state => {
            state.userId = '';
            state.phone = '';
            state.name = '';
            state.email = '';
            state.currentStep = AuthStepsEnum.phone;
            state.progressValue = 33;
            state.isLoggedIn = false;
            state.redirectUrl = '';
        },
    },
});

export const {
    setProgressValue,
    setLoginCurrentStep,
    setLoginPhone,
    setLoginOtp,
    setLoginName,
    setLoginEmail,
    setUserId,
    setShowProfileCard,
    setRedirectUrl,
    clearCurrentState,
    setIsLoggedIn,
} = auth.actions;

export default auth.reducer;
