export interface SingleObject {
    [key: string]: string | number | SingleObject;
}
const parseJwt = (token: string) => {
    function handleUTF8Object(obj: SingleObject): SingleObject {
        const result: SingleObject = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                result[key] = handleUTF8Object(obj[key] as SingleObject);
            } else if (typeof obj[key] === 'string') {
                result[key] = decodeUTF8(obj[key]);
            } else {
                result[key] = obj[key];
            }
        }
        return result;
    }
    function decodeUTF8(s) {
        const bytes = new Uint8Array(s.length);
        for (let b = 0; b < bytes.length; ++b) {
            bytes[b] = s.charCodeAt(b);
        }
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(bytes);
    }
    try {
        let payLoad = token.split('.')[1];
        // Replace non-url compatible chars with base64 standard chars
        payLoad = payLoad.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = JSON.parse(atob(payLoad));
        return handleUTF8Object(jsonPayload);
    } catch (e) {
        return false;
    }
};

export default parseJwt;
