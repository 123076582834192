// import fetch from '../libs/fetch';
import {
    ACQUISITION_BACKEND,
    COMMON_SERVER_HEADERS,
    NEXT_APP_SERVER_FETCH_CACHE_ENABLED,
} from '@/constants';
import {
    IMakesApi_Result_SELL,
    IModelApi_Result_SELL,
    INonShoppingListMakesApi_Result_SELL,
} from '@/types';
import { TIME_IN_SECONDS } from '@/constants/time';
import To from '@/libs/to-promise';
import pino from 'pino';

const logger = pino().child({ s: 'src/api-requests/acquisition.apis.ts' });

export const getMakesV3 = async (): Promise<IMakesApi_Result_SELL> => {
    const url = `${ACQUISITION_BACKEND}/v3/shoppingList/make`;
    const [err, res] = await To(
        fetch(url, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate:
                    TIME_IN_SECONDS.hour *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.error(err);
        return {} as IMakesApi_Result_SELL;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse getMakesV3 response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IMakesApi_Result_SELL;
    }
    return data;
};
export const getModelsV3 = async (
    makeId: string
): Promise<IModelApi_Result_SELL> => {
    const url = `${ACQUISITION_BACKEND}/v3/shoppingList/model/${makeId}`;
    const [err, res] = await To(
        fetch(url, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate:
                    TIME_IN_SECONDS.hour *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.info(err);
        return {} as IModelApi_Result_SELL;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse getModelsV3 response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IModelApi_Result_SELL;
    }
    return data;
};
export const getNonshoppingListMakes =
    async (): Promise<INonShoppingListMakesApi_Result_SELL> => {
        const url = `${ACQUISITION_BACKEND}/v2/nonShoppingList/make`;
        const [err, res] = await To(
            fetch(url, {
                headers: { ...COMMON_SERVER_HEADERS },
                next: {
                    revalidate:
                        TIME_IN_SECONDS.hour *
                        Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
                },
            })
        );
        if (err) {
            logger.info(err);
            return {} as INonShoppingListMakesApi_Result_SELL;
        }
        const [dataParseError, data] = await To(res.json());
        if (dataParseError) {
            logger.info(
                'Error while trying to parse  getNonshoppingListMakes response.'
            );
            logger.info('original data provided is ', data);
            logger.error(dataParseError);
            return {} as INonShoppingListMakesApi_Result_SELL;
        }
        return data;
    };
